'use client';
import { FC, useEffect, useState, useRef } from 'react';
import fetcher from 'utils/fetcher';
import COLLECTIONQUERY from 'models/Collection/CollectionQuery';
import {
  DefaultCollection,
  CollectionItem,
} from 'models/Collection/CollectionType';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import { LeftArrow } from 'components/Slider/arrows';
import { RightArrow } from 'components/Slider/arrows';
import Head from 'next/head';
import Loading from 'components/Loading/Loading';
import ScrollMenuCard from './scrollMenuCard';

const FancySlider: FC = () => {
  const [data, setData] = useState<DefaultCollection | undefined>();
  const dataRef = useRef(null);
  let dataFetched = false;

  async function getData() {
    // need to update voices slug once its available via cms prod.
    if (!dataFetched) {
      const data = await fetcher(COLLECTIONQUERY, {
        contentAreaSlug: process.env.CONTENT_AREA_SLUG,
        slug: 'wonder-and-wander',
      });
      dataFetched = true;
      setData(data?.collection);
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        getData();
      }
    });
    if (dataRef.current) observer.observe(dataRef.current);
    return () => {
      if (dataRef.current) observer.unobserve(dataRef.current);
    };
  }, []);

  return (
    <div className="home-fancy-slider">
      <Head>
        <link href="/css/components/fancySlider.css" rel="stylesheet" />
        <link href="/css/components/scrollmenu.css" rel="stylesheet" />
      </Head>
      <section className="fancy-slider" ref={dataRef}>
        <div className="container">
          <h2 className="hdg-block fancy-slider-hdg">
            <img
              src="/img/wander-and-wonder.svg"
              alt="Wander & Wonder"
              className="home-wander-wordmark"
            />
          </h2>
        </div>
        {data ? (
          <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow}>
            {data.results.items.slice(0, 4).map((item: CollectionItem) => {
              return <ScrollMenuCard key={item.canonicalSlug} {...item} />;
            })}
          </ScrollMenu>
        ) : (
          <Loading />
        )}
      </section>
    </div>
  );
};

export default FancySlider;
