import { gql } from 'graphql-request';

import AudioFragment from '../Audio/AudioFragment';
import ContributorsFragment from '../Contributors/ContributorFragment';
import PrimaryVisualsFragment from '../PrimaryVisuals/PrimaryVisualsFragment';

interface FragmentParamaters {
  pageSize?: number;
  page?: number;
}

export const createCollectionFragment = (
  parameters?: FragmentParamaters
): string => {
  const pageSize = parameters?.pageSize || 10;
  const page = parameters?.page || 1;

  return gql`
    id
    title
    subtitle
    canonicalSlug
    resourceType
    publishDate
    updatedAt
    templateName
    descriptionText
    body
    appleId
    slugs
    contributors {
      ${ContributorsFragment}
    }
    primaryCollection {
      title,
      canonicalSlug
    }
    primaryVisuals {
      ${PrimaryVisualsFragment}
      video {
        url
        caption
        background
        credit {
          url
          name
        }
      }
    }
    audio {
      ${AudioFragment}
    }
    embeddedAssets {
      audio
      attachments
      images
      oembeds
    }
    results(pageSize: ${pageSize} page: ${page}) {
      totalPages
      currentPage
      nextPage
      previousPage
      items {
        id
        title
        canonicalSlug
        descriptionText
        publishDate
        resourceType
        body
        audio {
          ${AudioFragment}
        }
        contributors {
          ${ContributorsFragment}
        }
        embeddedAssets {
          audio
          attachments
          images
          oembeds
        }
        primaryVisuals {
          ${PrimaryVisualsFragment}
            video {
              background
              caption
              credit {
                name
                url
            }
              url
          }
        }
        ... on Link {
          canonicalUrl
          destination
        }
        ... on Episode {
          episodeNumber
          podcastBody
          primaryAudio {
            ${AudioFragment}
          }
        }
        ... on Story {
          shortTitle
          collectionRelatedLinks {
            title
            prefix
            url
          }
          primaryAudio {
            ${AudioFragment}
          }
          primaryCollection {
            title
            canonicalSlug
            resourceType
          }
        }
        ... on Profile {
          jobTitle
        }
      }
    }

  `;
};

export interface CollectionParams extends FragmentParamaters {
  page?: number;
  slug: string;
  pageSize?: number;
  contentAreaSlug: string;
}

export const createCollectionQuery = (params: CollectionParams): string => {
  return gql`
    query CollectionQuery {
      collection(slug: "${params.slug}", contentAreaSlug: "${
    params.contentAreaSlug
  }") {
        ${createCollectionFragment(params)}
      }
    }
  `;
};

const COLLECTIONQUERY = gql`
  query Collection($slug: String!, $contentAreaSlug: String!) {
    collection(slug: $slug, contentAreaSlug: $contentAreaSlug) {
      ${createCollectionFragment()}
    }
    memberDrivePotlatch: potlatch(slug: "membership/current-membership") {
      json
    }
  }
`;

export default COLLECTIONQUERY;
