import { gql } from 'graphql-request';

const instances = gql`
  instances {
      url
      width
      height
  }
`;

const theRest = gql`
  contentArea
  dateTaken
  dateline
  fallback
  longCaption
  shortCaption
  xid
  caption
  credit {
    name
    url
  }
`;

const PrimaryVisuals = gql`
  video {
    url
    caption
    background
    credit {
      name
      url
    }
  }
  social {
    preferredAspectRatio {
      slug
      ${instances}
    }
    aspect_ratios: aspectRatios {
      square {
        ${instances}
      }
      thumbnail {
        ${instances}
      }
      widescreen {
        ${instances}
      }
      uncropped {
        ${instances}
      }
      portrait {
        ${instances}
      }
      normal {
        ${instances}
      }
    }
   ${theRest} 
  }
  lead {
    preferredAspectRatio {
      ${instances}
      slug
    }
    aspect_ratios: aspectRatios {
      square {
        ${instances}
      }
      widescreen {
        ${instances}
      }
      uncropped {
        ${instances}
      }
      portrait {
        ${instances}
      }
      normal {
        ${instances}
      }
    }
   ${theRest} 
  }
  thumbnail {
    type
    preferredAspectRatio {
      ${instances}
      slug
    }
    aspect_ratios: aspectRatios {
      square {
        ${instances}
      }
      thumbnail {
        ${instances}
      }
      widescreen {
        ${instances}
      }
      uncropped {
        ${instances}
      }
      portrait {
        ${instances}
      }
      normal {
        ${instances}
      }
    }
   ${theRest} 
  }   
`;

export default PrimaryVisuals;
