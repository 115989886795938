import { gql } from 'graphql-request';

const ContributorFragment = gql`
  profile {
    id
    title
    canonicalSlug
  }
  roles {
    name
  }
  order
`;

export default ContributorFragment;
