'use client';
import { FC, useEffect, useState, useRef } from 'react';
import fetcher from 'utils/fetcher';
import Link from 'next/link';
import Image from 'next/image';
import PODCASTHOMEQUERY from 'models/PodcastsHome/PodcastsHome';
import { CollectionItem } from 'models/Collection/CollectionType';
import { audioDownloadPrefix } from 'utils/utils';
import AudioPlayButton from 'components/AudioPlayButton/AudioPlayButton';
import Loading from 'components/Loading/Loading';

const Podcast: FC = () => {
  const [podcasts, setPodcasts] = useState<CollectionItem[] | undefined>();
  const podcastsRef = useRef(null);
  let dataFetched = false;

  function getData() {
    if (!dataFetched) {
      fetcher(PODCASTHOMEQUERY, {
        contentAreaSlug: process.env.CONTENT_AREA_SLUG,
        slug: 'featured-podcasts',
      }).then((response) => {
        setPodcasts(response?.collection.results.items);
        dataFetched = true;
      });
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        getData();
      }
    });
    if (podcastsRef.current) observer.observe(podcastsRef.current);
    return () => {
      if (podcastsRef.current) observer.unobserve(podcastsRef.current);
    };
  }, []);

  return (
    <section className="home-podcasts" ref={podcastsRef}>
      <h2 className="hdg-block hdg-block-slim">MPR News Podcasts</h2>
      {podcasts ? (
        <ul className="grid grid-home-podcasts">
          {podcasts?.slice(0, 6).map((item, i) => {
            const hasImageClass = item?.primaryVisuals?.thumbnail?.aspect_ratios
              .square?.instances[0].url
              ? 'hasImage'
              : 'noImage';
            const audioSquareImage =
              item?.primaryVisuals?.thumbnail?.aspect_ratios?.square
                ?.instances[0].url;
            return (
              <li key={i}>
                <div className="home-on-air-and-podcasts-item">
                  {item?.primaryVisuals?.thumbnail?.aspect_ratios?.square
                    ?.instances[0].url && (
                    <Link href={`/${item.canonicalSlug}`} passHref>
                      <Image
                        src={
                          item.primaryVisuals.thumbnail.preferredAspectRatio
                            .instances[0].url as string
                        }
                        alt={item.title}
                        height={112}
                        width={112}
                        className="teaser-primary-visual"
                      />
                    </Link>
                  )}
                  {item.results &&
                    item.results.items.map((podcastItm) => {
                      return (
                        <div
                          key={podcastItm.title}
                          className="home-on-air-and-podcasts-body"
                        >
                          <Link
                            className={hasImageClass}
                            href={`/${podcastItm.resourceType}/${podcastItm.canonicalSlug}`}
                          >
                            {podcastItm.title}
                          </Link>
                          {podcasts &&
                            podcastItm.audio[0] &&
                            podcastItm.audio[0].encodings[0] && (
                              <div
                                className={`${hasImageClass} home-main-story-controls-bottom`}
                              >
                                <AudioPlayButton
                                  audioTitle={podcastItm.title}
                                  label="Listen"
                                  audioSource={audioDownloadPrefix(
                                    podcastItm.audio[0].encodings[0]
                                      .playFilePath
                                  )}
                                  audioThumbnailSrc={audioSquareImage}
                                  audioThumbnailAlt={
                                    audioSquareImage &&
                                    podcastItm.primaryVisuals?.lead
                                      ?.shortCaption
                                  }
                                  showDuration={`${
                                    podcastItm.audio[0].encodings[0]
                                      .durationHms || ''
                                  }`}
                                  playBtnSm={true}
                                />
                              </div>
                            )}
                        </div>
                      );
                    })}
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <Loading />
      )}
      <Link href="/podcasts" className="btn btn-primary btn-slim home-btn-more">
        View All Podcasts
      </Link>
    </section>
  );
};

export default Podcast;
