import React, { FunctionComponent } from 'react';
import Link from 'next/link';
import { timeAgoUpdate } from '../../utils/timeAgoUpdate';

interface Props {
  publishDate: string;
  updatedPublishDate?: string;
  primaryCollection?: {
    canonicalSlug?: string;
    title?: string;
  };
}

const CategoryByline: FunctionComponent<Props> = (props) => {
  const time = timeAgoUpdate(props.publishDate, props.updatedPublishDate);

  return (
    <div className="home-time-collection-container">
      {props.publishDate && <div className="home-time-ago">{time}</div>}
      {props.primaryCollection && (
        <>
          {props.primaryCollection?.canonicalSlug && (
            <Link
              href={props.primaryCollection?.canonicalSlug}
              className="category-link"
              passHref
            >
              {props.primaryCollection?.title}
            </Link>
          )}
        </>
      )}
    </div>
  );
};

export default CategoryByline;
