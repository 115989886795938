export function findImageThumbnail(primaryVisual, aspect_ratio = 'square') { 
  if (!primaryVisual?.thumbnail) return null;
  if (primaryVisual.thumbnail.type == 'image/png' && primaryVisual.thumbnail.aspect_ratios.uncropped) {
    return primaryVisual.thumbnail?.aspect_ratios?.uncropped.instances[0].url
  }

  if (primaryVisual.thumbnail.aspect_ratios[aspect_ratio]?.instances[0].url) {
    return primaryVisual.thumbnail.aspect_ratios[aspect_ratio].instances[0].url
  }

  return null
}
