'use client';
import { FC, useEffect, useState, useRef } from 'react';
import fetcher from 'utils/fetcher';
import COLLECTIONQUERY from 'models/Collection/CollectionQuery';
import { CollectionItem } from 'models/Collection/CollectionType';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Link from 'next/link';
import IconTriangle from 'components/Icons/IconTriangle';
import AudioPlayButton from 'components/AudioPlayButton/AudioPlayButton';
import { audioDownloadPrefix } from 'utils/utils';
import Loading from 'components/Loading/Loading';
import AddFavoriteWrapper from 'components/Data/AddFavoriteWrapper';
import { Image } from '@apmg/mimas';
import { linkByTypeAs } from 'utils/cjsutils';

dayjs.extend(relativeTime);

const SpecialCoverage: FC = () => {
  const [story, setStory] = useState<CollectionItem | undefined>();
  const [collectionTitle, setCollectionTitle] = useState<string | undefined>();
  const [collectionPrimaryCategory, setCollectionPrimaryCategory] = useState<
    { title: string; slug: string } | undefined
  >();
  const audioSquareImage =
    story &&
    story.primaryVisuals.thumbnail.aspect_ratios.square?.instances[0].url;
  const storiesRef = useRef(null);
  let dataFetched = false;

  async function getData() {
    if (!dataFetched) {
      const data = await fetcher(COLLECTIONQUERY, {
        contentAreaSlug: process.env.CONTENT_AREA_SLUG,
        slug: 'special-coverage',
      });
      dataFetched = true;
      setCollectionTitle(data.collection.title);
      setCollectionPrimaryCategory({
        title: data.collection?.primaryCollection?.title,
        slug: data.collection?.primaryCollection?.canonicalSlug,
      });
      setStory(data?.collection.results.items[0]);
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        getData();
      }
    });
    if (storiesRef.current) observer.observe(storiesRef.current);
    return () => {
      if (storiesRef.current) observer.unobserve(storiesRef.current);
    };
  }, []);

  if (!story) {
    return (
      <section
        className="home-special-coverage container"
        id="special-coverage"
        ref={storiesRef}
      >
        <Loading />
      </section>
    );
  }
  return (
    <section
      className="home-special-coverage container"
      id="special-coverage"
      ref={storiesRef}
    >
      {story && story?.primaryVisuals?.thumbnail && (
        <Link
          href={linkByTypeAs(story)}
          passHref
          aria-hidden /* prevent duplicate link */
        >
          <Image
            image={story.primaryVisuals.thumbnail}
            sizes="80vw"
            alt={story.title}
          />
        </Link>
      )}
      <div className="home-special-coverage-grid">
        <div>
          <div className="home-special-coverage-upper">
            <div>
              <h3>{collectionTitle}</h3>
            </div>
            <div></div>
          </div>

          <div className="home-special-coverage-bottom">
            {collectionPrimaryCategory?.title && (
              <div className="home-special-coverage-primary">
                <Link href={`/${collectionPrimaryCategory.slug}`} passHref>
                  <IconTriangle color={`var(--color-mpr-yellow)`} />{' '}
                  {collectionPrimaryCategory?.title}
                </Link>
              </div>
            )}
            <Link href={linkByTypeAs(story)} passHref>
              <h3>{story && story.title}</h3>
              <p>{story && story.descriptionText}</p>
            </Link>
            {story && typeof story.primaryAudio != 'undefined' && (
              <div className="home-main-story-controls-bottom">
                {story.primaryAudio && (
                  <AudioPlayButton
                    audioTitle={story.title}
                    label="Listen"
                    audioSource={audioDownloadPrefix(
                      story.primaryAudio.encodings[0].playFilePath
                    )}
                    audioThumbnailSrc={audioSquareImage}
                    audioThumbnailAlt={
                      audioSquareImage &&
                      story.primaryVisuals?.lead?.shortCaption
                    }
                    showDuration={`${
                      story.primaryAudio.encodings[0].durationHms || ''
                    }`}
                  />
                )}
                <AddFavoriteWrapper
                  title={story.title}
                  contentArea="mprnews"
                  resourceType={story.resourceType}
                  id={story.id}
                  slug={story.canonicalSlug}
                  showIcon={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SpecialCoverage;
