import { gql } from 'graphql-request';
import AudioFragment from '../Audio/AudioFragment';
import PrimaryVisualsFragment from '../PrimaryVisuals/PrimaryVisualsFragment';

const PODCASTHOMEQUERY = gql`
  query Collection($contentAreaSlug: String!) {
    collection(slug: "featured-podcasts", contentAreaSlug: $contentAreaSlug) {
      results {
        items {
          title
          canonicalSlug
          descriptionText
          primaryVisuals {
            ${PrimaryVisualsFragment}
          }
          ... on Collection {
            results(pageSize: 1) {
              items {
                title
                descriptionText
                canonicalSlug
                resourceType
                audio {
                  ${AudioFragment}
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default PODCASTHOMEQUERY;
