export const dateStringAbbrevMonth = (dte = new Date()) => {
  const monthNumber = dte.getMonth();
  let monthStyle;
  switch (monthNumber) {
    case 0:
    case 1:
    case 7:
    case 8:
    case 9:
    case 10:
    case 11:
      monthStyle = 'short';
      break;
    default:
      monthStyle = 'long';
      break;
  }

  let dteStr =  dte.toLocaleDateString('en-us', {
      weekday: 'long',
      month: monthStyle == 'short' ? 'short' : 'long',
      day: 'numeric',
      year: 'numeric',

  })


  if (monthStyle === 'short') {
    dteStr = dteStr.replace(/(Jan|Feb|Aug|Sep|Oct|Nov|Dec)/, "$1.");
  }

  if (dteStr.includes('Sep.')) {
    dteStr = dteStr.replace(/Sep\./, "Sept.");
  }

  return dteStr;

};
