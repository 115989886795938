import React, { FC } from 'react';

interface Props {
  defaultHeight?: boolean;
  white?: boolean;
}

const Loading: FC<Props> = ({ defaultHeight = true, white = false }) => {
  return (
    <div>
      <div className={`spinner ${white ? 'spinner-white' : ''}`}></div>
    </div>
  );
};

export default Loading;
