import React, { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconHalfEllipse: FC<Props> = ({ height = '60', width = '179' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 179 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden /* decorative */
    >
      <g id="Frame 14608">
        <g id="Group 251">
          <path
            id="Ellipse 14"
            d="M160 59.9999C153.171 59.9999 146.409 58.6549 140.1 56.0416C133.792 53.4284 128.059 49.5981 123.23 44.7694C118.402 39.9408 114.572 34.2083 111.958 27.8994C109.345 21.5905 108 14.8286 108 7.99987C108 1.17114 109.345 -5.59073 111.958 -11.8997C114.572 -18.2086 118.402 -23.941 123.23 -28.7697C128.059 -33.5983 133.792 -37.4286 140.1 -40.0419C146.409 -42.6551 153.171 -44.0001 160 -44.0001L160 7.99988L160 59.9999Z"
            fill="#EB5C40"
          />
          <circle id="Ellipse 15" cx="52" cy="7.99988" r="52" fill="#2EBA80" />
        </g>
      </g>
    </svg>
  );
};

export default IconHalfEllipse;
