import { gql } from 'graphql-request';

const AudioFragment = gql`
      id
      title
      description
      programId
      program
      durationHms
      downloadable
      transcriptText
      transcripts {
        mediaType
        formatSlug
        url
      }
      encodings {
        filename
        httpFilePath
        playFilePath
        format
        durationHms
      }
  `;

export default AudioFragment;
