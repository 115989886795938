import { FC, useContext } from 'react';
import {
  WeatherContext,
  WeatherContextType,
} from '../../../context/WeatherContext';
import Link from 'next/link';

const WeatherGlance: FC = () => {
  const context = useContext<WeatherContextType>(WeatherContext);
  const weather = context.weatherData?.currentWeather;

  return (
    <>
      {weather && (
        <div className="home-weather-glance">
          {weather.temperature ? (
            <>
              <div className="home-weather-glance-temp">
                {weather.temperature}º
              </div>
              {weather.weatherCodeIconPath && weather.weatherImageAlt[0] && (
                <div className="home-weather-glance-conditions">
                  <img
                    className="weatherIcon weatherIcon-current"
                    src={`/siteicons/weatherkitIcons/svg/${weather.weatherCodeIconPath}.svg`}
                    alt={`${
                      weather.weatherImageAlt[0] &&
                      weather.weatherImageAlt[0].description
                    }`}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="type-sm">Loading current weather…</div>
          )}
          <Link href="/weather" className="btn btn-primary btn-slim type-sm">
            Weather &amp; Updraft&thinsp;&reg;
          </Link>
        </div>
      )}
    </>
  );
};

export default WeatherGlance;
