import React, { useState } from 'react';
import { TOTAL_ELECTORS, PARTY_CSS_CLASSES } from '../config';
import { slugify, useDefaultImg } from '../util';
import PropTypes from 'prop-types';
import Svg from '../Svg';

const PresidentNational = ({ candidates, compact = true }) => {
  const [displayCandidate, setDisplayCandidate] = useState();

  const getSortedCandidates = (cdts = candidates) => {
    return cdts.sort((a, b) => {
      if (a.natPopularVote > b.natPopularVote) {
        return -1;
      }
      if (a.natPopularVote < b.natPopularVote) {
        return 1;
      }
      return 0;
    });
  };

  let total_votes = candidates.reduce(
    (_accum, candidate) => candidate.natPopularVote
  );

  const top_candidates = [];
  const democrat = candidates.find(
    (candidate) => candidate.party.toLowerCase() == 'dem'
  );
  top_candidates.push(democrat);

  const republican = candidates.find(
    (candidate) => candidate.party.toLowerCase() == 'gop'
  );
  top_candidates.push(republican);

  const buildCandidateNodes = () => {
    const cnddts = total_votes == 0 ? top_candidates : candidates;
    let sorted = getSortedCandidates(cnddts);
    return sorted.map((candidate) => {
      const tagClasses = `candidate_tag ${PARTY_CSS_CLASSES[candidate.party]}`;
      const pctClasses = `candidate_pct ${
        candidate.winner === true ? 'candidate_pct_win' : ''
      } ap`;
      const winTag = candidate.winner ? (
        <div className="candidate_win">✔︎ AP Projected Win</div>
      ) : null;

      if (compact) {
        return (
          <li
            className="candidate candidate-compact"
            key={candidate.candidateID}
          >
            <div className="candidate_stats">
              <img
                src={`/data/imgs/candidates/${slugify(candidate.name)}.png`}
                alt={candidate.name}
                className="candidate_tag_img"
                onError={useDefaultImg}
              />
              <h6 className="hdg hdg-6">{candidate.name}</h6>
              <span className="candidate_votes candidate_votes_inline">
                {' '}
                {candidate.electorsWon} E.V.
              </span>
            </div>
            {winTag}
          </li>
        );
      }
      return (
        <li
          className={`candidate ${
            displayCandidate === candidate.candidateID ? 'active' : ''
          }`}
          key={candidate.candidateID}
        >
          <div className={tagClasses}>
            <img
              src={`/data/imgs/candidates/${slugify(candidate.name)}.png`}
              alt={candidate.name}
              className="candidate_tag_img"
              onError={useDefaultImg}
            />
            <span className="candidate_tag_party">{candidate.party}</span>
          </div>
          <div className="candidate_stats">
            <h5 className="hdg hdg-5">{candidate.name}</h5>
            <div className={pctClasses}>
              {candidate.winner && <span>✔︎ </span>}
              {candidate.electorsWon} E.V.
            </div>
            <div className="candidate_votes">
              <span className="candidate_votes_em candidate_votes_inline">
                {Math.round(candidate.natPopularVotePct * 100)}%
              </span>
              {candidate.natPopularVote.toLocaleString()}
            </div>
          </div>
        </li>
      );
    });
  };

  const buildCandidateBars = () => {
    return getSortedCandidates().map((candidate) => {
      const raceBarStyle = {
        width: candidate.natPopularVotePct * 100 + '%',
      };
      const raceBarClass = `graph_segment ${
        PARTY_CSS_CLASSES[candidate.party]
      }`;
      return (
        <div
          onMouseOver={() => setDisplayCandidate(candidate.candidateID)}
          onFocus={() => setDisplayCandidate(candidate.candidateID)}
          onMouseOut={() => setDisplayCandidate(undefined)}
          onBlur={() => setDisplayCandidate(undefined)}
          key={candidate.candidateID}
          className={`${raceBarClass} ${
            displayCandidate === candidate.candidateID ? 'active' : ''
          }`}
          style={raceBarStyle}
        >
          <span className="invisible">
            {`${candidate.name}: ${Math.round(
              candidate.natPopularVotePct * 100
            )}%`}
          </span>
        </div>
      );
    });
  };

  const [leftCandidate, rightCandidate] = getSortedCandidates();
  const leftStyle = {
    width: `${(leftCandidate.electorsWon / TOTAL_ELECTORS) * 100}%`,
  };
  const leftLeadingStyle = {
    width: `${(leftCandidate.electorsLeading / TOTAL_ELECTORS) * 100}%`,
  };
  const rightStyle = {
    width: `${(rightCandidate.electorsWon / TOTAL_ELECTORS) * 100}%`,
  };
  const rightLeadingStyle = {
    width: `${(rightCandidate.electorsLeading / TOTAL_ELECTORS) * 100}%`,
  };

  if (compact) {
    return (
      <div className="race">
        <div className="race_header">
          <div className="split">
            <div className="split_left">
              <h5 className="hdg hdg-5">President: Electoral Votes</h5>
            </div>
            <div className="split_right">
              <p>270 electoral votes to win</p>
            </div>
          </div>
        </div>
        <div className="race_graph race_graph-bop">
          <div className="graph graph-bop">
            <div
              style={leftStyle}
              className={`graph_segment ${
                PARTY_CSS_CLASSES[leftCandidate.party]
              }`}
            >
              <span className="invisible">{`${leftCandidate.name}: ${leftCandidate.electorsWon}`}</span>
            </div>
            <div
              style={rightStyle}
              className={`graph_segment ${
                PARTY_CSS_CLASSES[rightCandidate.party]
              }`}
            >
              <span className="invisible">{`${rightCandidate.name}: ${rightCandidate.electorsWon}`}</span>
            </div>
          </div>
        </div>
        <div className="race_body">
          <ol className="race_candidates">{buildCandidateNodes()}</ol>
        </div>
      </div>
    );
  }
  return (
    <div className="race race-ev">
      <div className="race_header">
        <div className="split">
          <div className="split_left">
            <h5 className="hdg hdg-5">
              <Svg icon="us" />
              National Results
            </h5>
          </div>
          <div className="split_right">270 E.V. to win</div>
        </div>
      </div>
      <div className="race_graph_overlay">Electoral Votes</div>
      <div className="race_graph_info" />
      <div className="race_graph race_graph-bop">
        <div className="race_graph_legend" aria-hidden="true">
          <div className="legend">
            <div className="legend_won">E.V. States Won</div>
            <div className="legend_trending">E.V. States Trending</div>
          </div>
        </div>
        <div className="race_graph_val race_graph_val-left">
          {leftCandidate.electorsWon}
        </div>
        <div className="race_graph_val race_graph_val-right">
          {rightCandidate.electorsWon}
        </div>
        <div className="graph graph-bop graph-ev">
          <div
            style={leftStyle}
            className={`graph_segment left noPointer ${
              PARTY_CSS_CLASSES[leftCandidate.party]
            }`}
          >
            <span className="invisible">{`${leftCandidate.name}: ${leftCandidate.electorsWon}`}</span>
          </div>
          <div
            style={leftLeadingStyle}
            className={`graph_segment left leading noPointer ${
              PARTY_CSS_CLASSES[leftCandidate.party]
            }`}
          >
            <span className="invisible">{`${leftCandidate.name}: ${leftCandidate.electorsWon}`}</span>
          </div>
          <div
            style={rightStyle}
            className={`graph_segment right noPointer ${
              PARTY_CSS_CLASSES[rightCandidate.party]
            }`}
          >
            <span className="invisible">{`${rightCandidate.name}: ${rightCandidate.electorsWon}`}</span>
          </div>
          <div
            style={rightLeadingStyle}
            className={`graph_segment leading right noPointer ${
              PARTY_CSS_CLASSES[rightCandidate.party]
            }`}
          >
            <span className="invisible">{`${rightCandidate.name}: ${rightCandidate.electorsWon}`}</span>
          </div>
        </div>
      </div>
      <div className="race_graph_overlay">Popular Vote</div>
      <div className="race_graph">
        <div className="graph">{buildCandidateBars()}</div>
      </div>
      <div className="race_body race_body-pad">
        <ol className="race_candidates">{buildCandidateNodes()}</ol>
      </div>
    </div>
  );
};

PresidentNational.propTypes = {
  compact: PropTypes.bool,
  candidates: PropTypes.array,
};

export default PresidentNational;
