import React from 'react';
import { useSession } from 'next-auth/react';

const Greeting = () => {
  const { data: session } = useSession();
  const timegreeting = () => {
    const hourofday = new Date().getHours();
    let message;
    if (hourofday < 12) {
      message = 'Good morning';
    } else if (hourofday >= 12 && hourofday < 17) {
      message = 'Good afternoon';
    } else {
      message = 'Good evening';
    }
    return message;
  };

  return (
    <div className="home-top-greeting" data-testid="greeting">
      {timegreeting()}
      {session?.user?.given_name ? `, ${session.user?.given_name}` : ''}!
    </div>
  );
};

export default Greeting;
