import { getTime } from '../utils/utils';
import { differenceInHours } from 'date-fns';

export function timeAgoUpdate(pubDate, upDate) {
  const currentTime = new Date();
  const publishedDt = new Date(pubDate);
  const updatedPubDt = upDate == null ? new Date(pubDate) : new Date(upDate);
  const hoursDifference = differenceInHours(currentTime, updatedPubDt);
  const timeToUse = hoursDifference == 0 ? publishedDt : updatedPubDt;
  const diffInDays = Math.floor(
    (currentTime.getTime() - timeToUse.getTime()) / (1000 * 60 * 60 * 24)
  );

  const timeUpd = getTime(updatedPubDt);
  const time = getTime(publishedDt);

  switch (true) {
    case (upDate == null):
      if (hoursDifference < 24) {
        return `${time}`;
      } else if (hoursDifference >= 24 && diffInDays === 1) {
        return `Yesterday`;
      } else if (hoursDifference >= 48 && hoursDifference <= 120 && diffInDays >= 2) {
        return `${diffInDays} days ago`;
      } else if (hoursDifference >= 120 ) { // after 5 days
        return '';
      }
      break;
    case (upDate != null):
      if (hoursDifference <= 24 && diffInDays === 0) {
        return `Updated at ${timeUpd}`;
      } else if (hoursDifference >= 48 && diffInDays === 0) {
        return `Updated at ${timeUpd}`;
      } else if (hoursDifference <= 48 && diffInDays === 1) {
        return `Updated yesterday`;
      } else if (hoursDifference >= 48 && hoursDifference <= 120 && diffInDays >= 2 ) {
        return `Updated ${diffInDays} days ago`;
      } else if (hoursDifference >= 120) { // after 5 days
        return '';
      }
      break;
    default:
      return '';
  }
}