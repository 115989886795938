'use client';
import { FC, useRef, useContext } from 'react';
import IconUpdraft from 'components/Icons/IconUpdraft';
import IconLatest from 'components/Icons/IconLatest';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import Link from 'next/link';
import { linkByTypeAs } from 'utils/cjsutils';
import {
  WeatherContext,
  WeatherContextType,
} from '../../../context/WeatherContext';

dayjs.extend(utc);
dayjs.extend(timezone);

const WeatherSideBar: FC = () => {
  const context = useContext<WeatherContextType>(WeatherContext);
  const updraft = context.updraft;
  const weather = context.weatherData?.currentWeather;
  const updraftRef = useRef(null);
  const time = dayjs(new Date()).tz('America/Chicago');

  return (
    <div>
      {weather && (
        <section className="home-weather">
          {context.weatherData?.currentWeather && (
            <>
              <h2 className="home-weather-location hdg-block hdg-block-slim">
                Twin Cities Weather
              </h2>
              <div className="home-weather-first-part">
                <div className="home-weather-first-part-inner">
                  <div>
                    <div className="home-weather-main-temp">
                      {weather.temperature && weather.temperature}º
                    </div>
                    <div className="home-weather-time">
                      {time.format('h:mm A')}
                    </div>
                  </div>
                </div>
                {weather.weatherCodeIconPath && weather.weatherImageAlt[0] && (
                  <img
                    className="weatherIcon weatherIcon-current"
                    src={`/siteicons/weatherkitIcons/svg/${weather.weatherCodeIconPath}.svg`}
                    alt={`${
                      weather.weatherImageAlt[0] &&
                      weather.weatherImageAlt[0].description
                    }`}
                  />
                )}
              </div>
            </>
          )}
          <Link href="/weather" className="btn btn-primary btn-slim">
            View forecast and live radar
          </Link>
        </section>
      )}
      {updraft && (
        <section className="home-updraft" id="updraft" ref={updraftRef}>
          <div className="home-updraft-meteorologists">
            <figure>
              <div className="home-updraft-met-photos">
                <img
                  src="/img/paul-huttner.jpg"
                  alt="Paul Huttner"
                  height="180"
                  width="180"
                  loading="lazy"
                />
                <img
                  src="/img/sven-sundgaard.jpg"
                  alt="Sven Sundgaard"
                  height="180"
                  width="180"
                  loading="lazy"
                />
              </div>
              <figcaption className="home-updraft-met-caption type-sm">
                MPR’s chief meteorologist Paul Huttner and MPR News
                meteorologist Sven Sundgaard
              </figcaption>
            </figure>
          </div>
          <Link href="/weather-and-climate/updraft" passHref>
            <div className="home-updraft-heading">
              <IconUpdraft />
              <h3 className="btn btn-primary btn-slim type-lg">
                Updraft&thinsp;&reg;
              </h3>
            </div>
          </Link>
          <div className="home-updraft-subtitle">
            Weather news, forecasts and more from our weather team
          </div>
          <div className="home-updraft-inner-container">
            <div>
              <IconLatest />
            </div>
            {updraft?.map((item, i) => {
              return (
                <div key={i}>
                  <Link href={linkByTypeAs(item)}>{item.title}</Link>
                </div>
              );
            })}
          </div>
        </section>
      )}
    </div>
  );
};

export default WeatherSideBar;
