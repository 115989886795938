import React, { FC } from 'react';

interface Props {
  height?: string;
  width?: string;
}

const IconPinkEllipse: FC<Props> = ({ height = '45', width = '183' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 183 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden /* decorative */
    >
      <ellipse
        id="Ellipse 15"
        cx="91.5"
        cy="91.5"
        rx="91.5"
        ry="91.5"
        transform="rotate(90 91.5 91.5)"
        fill="#FC6D80"
      />
    </svg>
  );
};

export default IconPinkEllipse;
