import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import HomeOnAir from './components/onAir';
import MnTodayPromo from 'endpoints/Home/components/mnTodayPromo';
import LatestNews from 'endpoints/Home/components/latestNews';
import Podcast from './components/podcast';
import FancySlider from './components/fancySlider';
import SpecialCoverage from 'endpoints/Home/components/specialCoverage';
import FeaturedStories from 'endpoints/Home/components/featuredStories';
import Boxes from './components/boxes';
import ShareIdeasBanner from './components/shareIdeasBanner';
import AdvertisementBanner from 'components/Advertisement/AdvertisementBanner';
import FeaturedEvents from './components/events';
import AlertWrapper from 'components/Alert/AlertWrapper';
import Greeting from './components/Greeting';
import { DefaultCollection } from 'models/Collection/CollectionType';
import MoreNews from './components/moreNews';
import WeatherSideBar from './components/weatherSideBar';
import WeatherGlance from './components/weatherGlance';
import { WeatherProvider } from 'context/WeatherContext';
import { dateStringAbbrevMonth } from 'utils/dateUtils';
import useSWR from 'swr';
import RaceFull from 'components/elections/RaceFull';
import PresidentNational from 'components/elections/PresidentNational';
import PresidentMinn from 'components/elections/PresidentMinn';
import Bop from 'components/elections/BOP';
import {
  ElectionDataShape,
  ElectionConfig,
  StateRace,
} from 'components/elections/ElectionTypes';

export interface HomeProps {
  collection?: DefaultCollection;
  radar: {
    slug: string;
    json: string;
  };
  electionpotlatch?: {
    slug: string;
    json: string;
  };
}

const Home = ({ collection, radar, electionpotlatch }: HomeProps) => {
  const [dte, setDte] = useState('');
  const [mounted, setMounted] = useState(false);
  const [electionData, setElectionData] = useState<ElectionDataShape | null>();
  const fetcher = (url: string) =>
    fetch(url).then((r) => {
      r.json().then((res) => {
        setElectionData(res);
      });
    });

  useSWR(
    mounted
      ? `${process.env.NEXT_PUBLIC_ELECTIONS_API}/2024-11-05/mprnews`
      : null,
    fetcher,
    {
      refreshInterval: 25000,
    }
  );
  const electionconfig: ElectionConfig =
    electionpotlatch && JSON.parse(electionpotlatch.json);

  function findrace(key: string, type = 'mnhouse') {
    function slugify(str: string) {
      return str.toLowerCase().replaceAll(' ', '_');
    }
    if (!electionData) return;
    const data = Object.fromEntries(
      Object.entries(electionData).map(([k, v]) => [k.toLowerCase(), v])
    );

    if (type == 'bopmnhouse' || type == 'bopmnsenate') {
      return findStateBop(type, data);
    }

    if (type == 'mnhouse' || type == 'mnsenate') {
      return data[type].find(
        (race: StateRace) =>
          `${slugify(race.district)}_${slugify(race.name)}` === key
      );
    }
    return data[type];
  }

  function findStateBop(type: string, data: any) {
    let newtype;
    if (type == 'bopmnhouse') newtype = 'mnhousebop';
    if (type == 'bopmnsenate') newtype = 'mnsenatebop';
    if (!newtype) return null;

    return data[newtype] as
      | ElectionDataShape['mnHouseBop']
      | ElectionDataShape['mnSenateBop'];
  }

  useEffect(() => {
    const date = dateStringAbbrevMonth();
    setDte(date);
  }, []);

  useEffect(() => {
    setMounted(true);
  });

  let widgetkey, race;
  return (
    <div data-mpr-content-topic="homepage">
      <AlertWrapper resourceType="home" />
      <Head>
        <link href="/css/pages/home.css" rel="stylesheet" />
        <link href="/css/pages/electionHub.css" rel="stylesheet" />
      </Head>
      <div className="wrapper">
        <div className="container">
          <div className="home-top home-segment">
            <div className="header-ad">
              <AdvertisementBanner id="mpr-ad-1" />
            </div>
            <div className="home-top-greeting-container">
              <Greeting />
              {dte && <div>{dte}</div>}
              <WeatherProvider>
                <WeatherGlance />
              </WeatherProvider>
            </div>
          </div>
        </div>
        <div className="container grid grid-home-news">
          <div className="home-card home-topnews">
            <FeaturedStories radar={radar} collection={collection} />
            <div className="container">
              {electionconfig?.results_on &&
                electionData &&
                electionconfig.widgets.length > 1 && (
                  <h2 className="hdg hdg-2">
                    <Link href="https://elections.mpr.org/" target="_blank">
                      Election Results
                    </Link>
                  </h2>
                )}
              {electionconfig?.results_on &&
                electionData &&
                electionconfig.widgets.map((widget, idx) => {
                  if (
                    widget.widget.startsWith('mnhouse') ||
                    widget.widget.startsWith('mnsenate')
                  ) {
                    widgetkey = widget.widget.split('/')[1];
                    race = findrace(widgetkey, widget.widget.split('/')[0]);
                  } else {
                    race = findrace(widget.widget, widget.widget);
                  }
                  if (widget.widget.toLowerCase().includes('bop')) {
                    return <Bop {...race} key={idx} />;
                  }
                  if (widget.widget == 'presidentmn') {
                    return <PresidentMinn {...race[0]} key={idx} />;
                  }
                  if (widget.widget == 'presidentnational') {
                    return <PresidentNational candidates={race} key={idx} />;
                  }
                  if (Array.isArray(race)) {
                    return (
                      <div key={idx}>
                        {race[0] && <RaceFull {...race[0]} />}
                      </div>
                    );
                  }
                  return <div key={idx}>{race && <RaceFull {...race} />}</div>;
                })}
            </div>
            {electionconfig?.results_on && electionData && (
              <Link
                href="https://elections.mpr.org/"
                target="_blank"
                className="basic-link"
              >
                See full election results
              </Link>
            )}
            <LatestNews radar={radar} collection={collection} />
          </div>

          <div className="home-today">
            <WeatherProvider>
              <WeatherSideBar />
            </WeatherProvider>
            <MnTodayPromo radar={radar} />
          </div>
          <div className="home-promobox">
            <Boxes />
          </div>
          <div className="home-morenews">
            <MoreNews radar={radar} collection={collection} />
          </div>
          <div className="home-audio grid grid-home-on-air-podcasts">
            <HomeOnAir />
            <Podcast />
          </div>
        </div>
      </div>
      <section className="home-segment">
        <FancySlider />
      </section>
      <section className="home-what-next home-segment container">
        <ShareIdeasBanner />
      </section>
      <section className="home-special-coverage home-segment container-triangle-bg">
        <SpecialCoverage />
      </section>
      <div className="wrapper">
        <FeaturedEvents />
      </div>
      <div className="header-ad">
        <AdvertisementBanner id="mpr-ad-3" />
      </div>
    </div>
  );
};

export default Home;
