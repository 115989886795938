'use client';
import { FC, useEffect, useState, useRef } from 'react';
import fetcher from 'utils/fetcher';
import QUERY from '../events.gql';
import Link from 'next/link';
import { EventContent } from 'models/Event/EventType';
import dayjs from 'dayjs';
import { Image } from '@apmg/mimas';

const FeaturedEvents: FC = () => {
  const [events, setEvents] = useState<EventContent[] | undefined>();
  const eventsRef = useRef(null);
  let dataFetched = false;

  async function getData() {
    if (!dataFetched) {
      try {
        const data = await fetcher(QUERY, {
          contentAreaSlug: process.env.CONTENT_AREA_SLUG,
          occurringAfter: new Date().toISOString(),
        });
        dataFetched = true;
        setEvents(data.events.items);
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        getData();
      }
    });
    if (eventsRef.current) observer.observe(eventsRef.current);
    return () => {
      if (eventsRef.current) observer.unobserve(eventsRef.current);
    };
  }, []);

  return (
    <section className="home-events home-segment container" ref={eventsRef}>
      {events && events.length > 0 && (
        <div className="grid grid-home-events grid-with-border">
          <h2 className="hdg-block">MPR Events</h2>
        </div>
      )}
      <ul className="grid grid-home-events">
        {events &&
          events?.slice(0, 3).map((item, i) => {
            return (
              <li key={i} className="home-event-listing">
                <div className="home-event-date">
                  {dayjs(item.occurrences[0].startTime).format('MMMM D')}
                </div>
                {item.primaryVisuals?.thumbnail?.aspect_ratios?.uncropped && (
                  <Link href={`/event/${item.canonicalSlug}`} passHref>
                    <Image
                      image={item.primaryVisuals.thumbnail}
                      sizes="(min-width: 72em) 20vw,  80vw"
                      aspectRatio="thumbnail"
                    />
                  </Link>
                )}
                <div className="home-event-text">
                  <Link href={`/event/${item.canonicalSlug}`} passHref>
                    <div className="home-event-title">{item.title}</div>
                    <div
                      className="home-event-more"
                      aria-hidden /* on a screen reader, this text just adds more noise */
                    >
                      More information
                    </div>
                  </Link>
                </div>
              </li>
            );
          })}

        {events && events.length > 0 && (
          <li className="home-events-view-all-btn">
            <Link href="/events" className="btn btn-primary">
              View All Events
            </Link>
          </li>
        )}
      </ul>
    </section>
  );
};

export default FeaturedEvents;
