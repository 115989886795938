'use client';
import { FC } from 'react';
import Link from 'next/link';
import IconPinkEllipse from 'components/Icons/IconPinkEllipse';
import IconHalfEllipse from 'components/Icons/IconHalfEllipse';

const ShareIdeasBanner: FC = () => {
  return (
    <div className="share-ideas-banner">
      <div className="share-ideas-inner">
        <h2>What stories should we cover next?</h2>
        Share your ideas with our editors, and you may see it on MPR News.
      </div>
      <div className="share-ideas-btn">
        <Link className="btn btn-primary" href="/tell">
          Share your Ideas
        </Link>
      </div>
      <IconPinkEllipse />
      <IconHalfEllipse />
    </div>
  );
};

export default ShareIdeasBanner;
