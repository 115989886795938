'use client';
import { FC, useEffect, useState, useRef } from 'react';
import fetcher from 'utils/fetcher';
import Link from 'next/link';
import Image from 'next/image';
import COLLECTIONQUERY from 'models/Collection/CollectionQuery';
import { CollectionItem } from 'models/Collection/CollectionType';
import { audioDownloadPrefix } from 'utils/utils';
import AudioPlayButton from 'components/AudioPlayButton/AudioPlayButton';
import { findImageThumbnail } from 'utils/imageUtils';

const HomeOnAir: FC = () => {
  const [stories, setStories] = useState<CollectionItem[] | undefined>();
  const storiesRef = useRef(null);
  let dataFetched = false;

  async function getData() {
    if (!dataFetched) {
      const data = await fetcher(COLLECTIONQUERY, {
        contentAreaSlug: process.env.CONTENT_AREA_SLUG,
        slug: 'on-air',
      });
      dataFetched = true;
      setStories(data?.collection.results.items);
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        getData();
      }
    });
    if (storiesRef.current) observer.observe(storiesRef.current);
    return () => {
      if (storiesRef.current) observer.unobserve(storiesRef.current);
    };
  }, []);

  return (
    <section className="home-on-air" ref={storiesRef}>
      <h2 className="hdg-block hdg-block-slim">Recommended 🎧</h2>
      <div>
        <ul className="grid grid-home-on-air">
          {stories?.slice(0, 6).map((item, i) => {
            const hasImageClass = findImageThumbnail(item?.primaryVisuals)
              ? 'hasImage'
              : 'noImage';
            const audioSquareImage = findImageThumbnail(item?.primaryVisuals);
            const usePlaceholderAlt =
              item?.primaryVisuals?.thumbnail?.aspect_ratios?.square?.instances[0].url.includes(
                'placeholder'
              )
                ? 'MPR News Logo'
                : item.primaryVisuals?.lead?.shortCaption;
            return (
              <li key={i}>
                {stories && (
                  <div className="home-on-air-and-podcasts-item">
                    {findImageThumbnail(item?.primaryVisuals) && (
                      <Image
                        src={findImageThumbnail(item.primaryVisuals)}
                        alt={`${usePlaceholderAlt}`}
                        height={112}
                        width={112}
                      />
                    )}
                    <div className="home-on-air-and-podcasts-body">
                      <Link
                        href={`/${item.resourceType}/${item.canonicalSlug}`}
                        className={hasImageClass}
                      >
                        {item.title}
                      </Link>
                      {stories && item?.primaryAudio?.encodings[0] && (
                        <div
                          className={`home-main-story-controls-bottom ${hasImageClass}`}
                        >
                          <AudioPlayButton
                            audioTitle={stories[0].title}
                            label="Listen"
                            audioSource={audioDownloadPrefix(
                              item.primaryAudio.encodings[0].playFilePath
                            )}
                            audioThumbnailSrc={
                              audioSquareImage ||
                              '/public/img/MPR-news-logo.svg'
                            }
                            audioThumbnailAlt={
                              audioSquareImage &&
                              item.primaryVisuals?.lead?.shortCaption
                            }
                            showDuration={`${
                              item.primaryAudio.encodings[0].durationHms || ''
                            }`}
                            playBtnSm={true}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </li>
            );
          })}
          <li>
            <Link
              href="/podcasts"
              className="btn btn-primary btn-slim home-btn-more"
            >
              View All Programs
            </Link>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default HomeOnAir;
