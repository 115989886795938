import React, { FC } from 'react';

interface Props {
  itemId?: string | number;
  children?: React.ReactNode;
  className?: string;
}

const Card: FC<Props> = ({ children, itemId, className }) => {
  return (
    <div data-item-id={itemId} className={className}>
      {children}
    </div>
  );
};

export default Card;
