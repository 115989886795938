'use client';
import { FC, useEffect, useState, useRef } from 'react';
import fetcher from 'utils/fetcher';
import COLLECTIONQUERY from 'models/Collection/CollectionQuery';
import { CollectionItem } from 'models/Collection/CollectionType';
import Link from 'next/link';
import Loading from 'components/Loading/Loading';
import { Image } from '@apmg/mimas';
import { linkByTypeAs } from 'utils/cjsutils';

const Boxes: FC = () => {
  const [collection, setCollection] = useState<CollectionItem[] | undefined>();
  const collectionRef = useRef(null);
  let dataFetched = false;

  async function getData() {
    if (!dataFetched) {
      const data = await fetcher(COLLECTIONQUERY, {
        contentAreaSlug: process.env.CONTENT_AREA_SLUG,
        slug: 'featured-collection',
      });
      dataFetched = true;
      XPathEvaluator;
      setCollection(data?.collection.results.items.slice(0, 6));
    }
  }

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        getData();
      }
    });
    if (collectionRef.current) observer.observe(collectionRef.current);
    return () => {
      if (collectionRef.current) observer.unobserve(collectionRef.current);
    };
  }, []);

  return (
    <div className="home-boxes" ref={collectionRef}>
      {collection ? (
        <>
          <ul className="home-boxes-slider">
            {collection?.map((item) => {
              return (
                <li key={item.title}>
                  <Link
                    href={linkByTypeAs(item)}
                    className="home-boxes-box"
                    passHref
                  >
                    <h3 className="home-boxes-label type-sm">{item.title}</h3>
                    <div className="home-boxes-box-img">
                      {item.primaryVisuals?.thumbnail && (
                        <Image
                          image={item.primaryVisuals.thumbnail}
                          sizes="360"
                          aspectRatio="square"
                        />
                      )}
                    </div>
                  </Link>
                </li>
              );
            })}
          </ul>
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Boxes;
