import React, { useState } from 'react';
import { PARTY_CSS_CLASSES } from '../config';
import { slugify } from '../util';
import PropTypes from 'prop-types';

const PresidentMinn = ({ candidates, precinctsReportingPct }) => {
  const [displayCandidate, setDisplayCandidate] = useState();

  const getSortedCandidates = () => {
    return candidates.sort((a, b) => {
      if (a.votecount > b.votecount) {
        return -1;
      }
      if (a.votecount < b.votecount) {
        return 1;
      }
      return 0;
    });
  };

  const buildCandidateNodesMini = () => {
    return getSortedCandidates().map((candidate) => {
      const pctClasses = `candidate_pct ${
        candidate.winner === true ? 'candidate_pct_win ap' : ''
      }`;

      return (
        <li
          className={`candidate candidate-compact ${
            displayCandidate === candidate.name ? 'active' : ''
          }`}
          key={`${slugify(candidate.name)}-minn-candidate`}
        >
          <div className="candidate_stats">
            <img
              src={`/data/imgs/candidates/${slugify(candidate.name)}.png`}
              alt={candidate.name}
              className="candidate_tag_img"
            />
            <div className="hdg hdg-6">{candidate.name}</div>
            <div className={pctClasses}>
              {Math.round(candidate.votepct * 100)}%
            </div>
            <div className="candidate_votes">
              {candidate.votecount.toLocaleString()}
            </div>
          </div>
        </li>
      );
    });
  };

  const buildCandidateBars = () => {
    return getSortedCandidates().map((candidate) => {
      const raceBarStyle = {
        width: candidate.votepct * 100 + '%',
      };

      const raceBarClass = `graph_segment ${
        PARTY_CSS_CLASSES[candidate.party]
      }`;

      return (
        <div
          onMouseOver={() => setDisplayCandidate(candidate.name)}
          onFocus={() => setDisplayCandidate(candidate.name)}
          onMouseOut={() => setDisplayCandidate(undefined)}
          onBlur={() => setDisplayCandidate(undefined)}
          key={`${slugify(candidate.name)}-minn-bar`}
          className={`${raceBarClass} ${
            displayCandidate === candidate.name ? 'active' : ''
          }`}
          style={raceBarStyle}
        >
          <span className="invisible">
            {`${candidate.name}: ${Math.round(candidate.votepct * 100)}%`}
          </span>
        </div>
      );
    });
  };
  return (
    <div className="race">
      <div className="race_header">
        <div className="split">
          <div className="split_left">
            <h4 className="hdg hdg-5">Minnesota Popular Vote for President</h4>
          </div>
          <div className="split_right">
            {Math.round(precinctsReportingPct * 100)}% Reporting
          </div>
        </div>
      </div>
      <div className="race_graph">
        {Math.round(precinctsReportingPct * 100) > 0 && (
          <div className="graph">{buildCandidateBars()}</div>
        )}
      </div>
      <div className="race_body">
        {Math.round(precinctsReportingPct * 100) > 0 ? (
          <ol className="race_candidates">{buildCandidateNodesMini()}</ol>
        ) : (
          <p>Polls close at 8pm, initial results coming soon.</p>
        )}
      </div>
    </div>
  );
};

PresidentMinn.propTypes = {
  candidates: PropTypes.array,
  precinctsReportingPct: PropTypes.number,
};

export default PresidentMinn;
